import React, { useState } from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import BackToolbar from "@components/modules/back-toolbar/";
import "@assets/scss/App.scss";
import Layout from "@components/layout";
import Header from "@components/header/Header";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { json } from "body-parser";
const SiteMap = ({ location }, props) => {
    const createDataTree = dataset => {
        let hashTable = Object.create(null)
        // add a childNodes array to each node
        dataset.forEach(aData => hashTable[aData.node.id] = { ...aData.node, childNodes: [] })
        let dataTree = []
        // loop over nodes, the child node has to have the Parent id
        dataset.forEach(aData => {
            // push the child node under the parent node
            if (aData.node.Parent) {
                hashTable[`Menu-headers_${aData.node.Parent.id}`].childNodes.push(hashTable[aData.node.id])
            } else {
                // this is the normal parent node
                dataTree.push(hashTable[aData.node.id])
            }
        })

        return dataTree
    }
    const headerLinks = useStaticQuery(graphql`
    {
        allStrapiMenuHeaders(filter: {Published: {eq: true}}, sort: {order: ASC, fields: Sort}) {
            edges {
                node {
                    id
                    menuLink {
                        id
                        Label
                        targetLink
                        page {
                            id
                            Slug
                        }
                    }
                    Parent {
                        id
                        Label
                        menuLink {
                            page {
                                Slug
                            }
                        }
                    }
                }
            }
        }
    }
`)
    const [menu, setMenu] = useState(createDataTree(headerLinks.allStrapiMenuHeaders.edges))
    console.log(headerLinks.allStrapiMenuHeaders.edges)
    return (
        <Layout>
            <SEO title="Sitemap" />
            <Helmet
                bodyAttributes={{
                    class: 'grey-bg'
                }}
            />
            <Header />

            {/* <div>
                {menu && menu.length > 0 ? menu.map((link, i) => {
                    return (
                        <ul>
                            {link.childNodes.length === 0 &&
                                <li key={i}><a href={link.menuLink.targetLink}>{link.menuLink.Label}</a></li>
                            }
                            {link.childNodes.length > 0 &&
                                <>
                                    <li><a href={link.menuLink.targetLink}>{link.menuLink.Label}</a>
                                        <ul>
                                            {link.childNodes.map((sublink) => {
                                                return (
                                                    <li><a href={sublink.menuLink.targetLink}>{sublink.menuLink.Label}</a></li>
                                                )
                                            })}
                                        </ul>
                                    </li></>
                            }
                        </ul>


                    )
                }) : ''}
            </div> */}
            <BackToolbar
                backLinkLabel="Back"
                showCreateAlert={false}
            />
            <section  className="sitemap-page">
            <Container>
                    <Row>
                        <Col xs={12}>
                        <h1 class="sitemap section-content-header__title h5 mb-29 mb-lg-0">Sitemap</h1>
                        <div className="sitemap-blk">
                <ul>
                    <li>
                        <a href="/candidates/">Candidates</a>
                        <ul>
                            <li><a href="/candidates/upload-your-cv/">Register as a Candidate</a></li>
                            <li><a href="/job-search/">Job Listing</a></li>
                            <li><a href="/candidates/refer-a-candidate/">Refer a Candidate</a></li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href='/employers/'>Employers</a>
                        <ul>
                            <li><a href="/employers/register/">Register as an Employer</a></li>
                            <li><a href="/employers/post-jobs/">Post Jobs</a></li>
                            <li><a href="/employers/subscription-plans/">Subscription Plans</a></li>
                            <li><a href="/employers/mentoring-and-training/">Mentoring &amp; Training</a></li>
                            <li><a href="/employers/refer-an-employer/">Refer an Employer</a></li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li><a href="/help-and-advice/">Help &amp; Advice</a></li>
                </ul>
                <ul>
                    <li>
                        <a href="/about/">About</a>
                        <ul>
                            <li><a href="/about/reviews/">Reviews</a></li>
                            <li><a href="/about/why-crux-careers/">Why Crux</a></li>
                            <li><a href="/about/our-people/">Our People</a></li>
                            <li><a href="/about/our-clients/">Our Clients</a></li>
                            <li><a href="/about/careers-with-crux/">Careers with Crux</a></li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li><a href="/contact/">Contact</a></li>
                </ul>
            </div>
                        </Col>
                    </Row>
            </Container>
            </section>

            <PopularSearch {...props}
                sectionClass=" section-grey-bg"
            />
            <Footer />
        </Layout>
    )
}
export default SiteMap